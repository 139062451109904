import React from "react";
import {ButtonGroup, Button} from "@george-labs.com/design-system";
import {isUserInRoles} from "../stripe/utils/actionStripeConfig";
import {useSelector} from "react-redux";


const ActionsModalFooter = ({primary, secondary, cancel}) => {

    const user = useSelector(state => state?.user?.identity);

    return (
        <ButtonGroup>
            {secondary && !isUserInRoles(user, ["SMART_MIT_solver_corporate"]) && !isUserInRoles(user, ["SMART_MIT_solver_corporate_english"]) ?
                <Button variant={Button.VARIANT.SECONDARY} onClick={secondary.onClick}
                        disabled={secondary.disabled}>
                    {secondary.text}
                </Button> : null}

            {cancel ?
                <Button variant={Button.VARIANT.SECONDARY} onClick={cancel.onClick}
                        disabled={cancel.disabled}>
                    {cancel.text}
                </Button> : null}


            {primary ?
                <Button variant={Button.VARIANT.PRIMARY} onClick={primary.onClick}
                        disabled={primary.disabled}>
                    {primary.text}
                </Button> : null}
        </ButtonGroup>
    )
};

export default ActionsModalFooter