class Resource {

    constructor() {
        if (window.location.host.startsWith("smart")) {
            this.baseUrl = window.location.origin;
        } else {
            this.baseUrl = process.env.REACT_APP_BE_URL;
        }
        this.webApiUrl = process.env.REACT_APP_WEBAPI_URL;
        this.mitModule = null;
    }

    /**
     * Auth api is exposed on mit module only (mit-gbiz doesn't expose this api)
     */
    getMitAuthApi() {
        return (this.webApiUrl) ? this.webApiUrl + "/smart-mit" : this.baseUrl + "/mit/api";
    }

    /**
     * Dispatch api exposed on mitfed module returns specific messenger module (for given cidla). This module's api will be called using getMitApi() as base address.
     */
    getMitDispatchApi() {
        return (this.webApiUrl) ? this.webApiUrl + "/smart-mitfed" : this.baseUrl + "/mitfed/api";
    }

    getMitApi() {
        return (this.webApiUrl) ? this.webApiUrl + this.getMitModuleWebApiPath() : this.baseUrl + this.getMitModulePath();
    }

    getMitModuleWebApiPath() {
        return this.mitModule === 'mit-gbiz' ? '/smart-mit-gbiz' : '/smart-mit';
    }

    getMitModulePath() {
        return this.mitModule === 'mit-gbiz' ? '/mit-gbiz/api' : '/mit/api';
    }

    checkStatus(response) {
        if(response.status >= 200 && response.status < 300) {
            return response;
        } else {
            throw new Error(response);
        }
    }

    setMitModule(mitModule) {
        this.mitModule = mitModule;
    }
}

export default new Resource();
