import {
    ADFS_ACCESS_EXPIRING, ADFS_ACCESS_EXPIRING_NOTED,
    ADFS_AUTH_FAILED, ALERT_SUCCESS,
    AUTH_INFO_FAILED,
    AUTH_INFO_LOADED, AUTH_INFO_LOADING, AUTH_REFRESH_FAILED, AUTH_REFRESHED, AUTH_REFRESHING,
} from "../core/const";
import Resource from "../core/serverResource";
import {registerInterceptor} from "../core/utils";
import { fireShowAlert } from "./alertActions";


export const authLoading = () => (
    {type: AUTH_INFO_LOADING}
);

export const authLoaded = (data) => (
    {type: AUTH_INFO_LOADED, data}
);

export const authLoadFailed = (error) => (
    {type: AUTH_INFO_FAILED, error}
);

export const authRefreshing = () => (
    {type: AUTH_REFRESHING}
);

export const authRefreshed = (data) => (
    {type: AUTH_REFRESHED, data}
);

export const authRefreshFailed = (error) => (
    {type: AUTH_REFRESH_FAILED, error}
);

export const adfsRequestFailed = () => (
    {type: ADFS_AUTH_FAILED}
);

export const expiringAccess = () => (
    {type: ADFS_ACCESS_EXPIRING}
);

export const expiringAccessNoted = () => (
    {type: ADFS_ACCESS_EXPIRING_NOTED}
);

export const getAccessToken = (code, clientId, scope, redirectUri) => {
    return (dispatch) => {
        dispatch(authLoading());
        const request = {
            code: code,
            clientId: clientId,
            scope: scope,
            redirectUri: redirectUri
        };

        return fetch(Resource.getMitAuthApi() + "/token", {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "web-api-key" : process.env.REACT_APP_WEBAPI_KEY
            },
            method: "POST",
            body: JSON.stringify(request)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(data => {
                registerInterceptor(data.access_token);
                dispatch(authLoaded(data));
                return Promise.resolve(data)
            })
            .catch(error => {
                // dispatch fireShowAlert not needed. Failure is handled by global modal dialog in AppWithAuth.js
                dispatch(authLoadFailed());
                return Promise.reject(error)
            })
    };
};

export const refreshAccessToken = (data, clientId, scope) => {
    const request = {
        clientId: clientId,
        scope: scope,
        refreshToken: data.refresh_token,
        grantType: 'refresh_token'
    };

    return (dispatch) => {
        dispatch(authRefreshing());
        return fetch(Resource.getMitAuthApi() + "/token/refresh", {
            headers: {
                "Content-Type" : "application/json",
                "Accept": "application/json"
            },
            method: "POST",
            body: JSON.stringify(request)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(data => {
                dispatch(authRefreshed(data));
                dispatch(fireShowAlert("Prodloužení přístupu úspěšně proběhlo", ALERT_SUCCESS, 10000));
                return Promise.resolve(data);
            }).catch(err => {
                //dispatch fireShowAlert not needed. Failure is handled by global modal dialog in AppWithAuth.js
                dispatch(authRefreshFailed(err));
                return Promise.reject(err)
            })

    }
};

