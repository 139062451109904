import Resource from "../core/serverResource";
import {
    ENVIRONMENT_PROPERTIES_ERROR,
    ENVIRONMENT_PROPERTIES_LOADED,
    ENVIRONMENT_PROPERTIES_LOADING
} from "../core/const";

export const environmentPropertiesLoading = () => (
    {type: ENVIRONMENT_PROPERTIES_LOADING}
);

export const environmentPropertiesLoaded = (properties) => (
    {type: ENVIRONMENT_PROPERTIES_LOADED, properties}
);

export const environmentPropertiesError = (error) => (
    {type: ENVIRONMENT_PROPERTIES_ERROR, error}
);

export function fireLoadEnvironment() {
    return (dispatch) => {
        dispatch(environmentPropertiesLoading());
        return fetch(Resource.getMitApi() + "/configuration")
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(properties => {
                dispatch(environmentPropertiesLoaded(properties));
                return Promise.resolve();
            })
            .catch(err => {
                dispatch(environmentPropertiesError(err.message));
                console.log("Unable to load properties", err)
                return Promise.reject(err);
            });
    }
}

/**
 * In case the messenger process is started from Starter, we need to wait until the CASE_CREATED event is propagated to MITFED, so it can
 * assign correct messenger module (mit/mig-gbiz) to given cidla.
 * @param cidla
 * @return resolved messenger module
 */
async function waitForMitfedResponse(cidla) {
    while (true) {
        const result = await fetch(Resource.getMitDispatchApi() + "/dispatcher/" + cidla)
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(body => {
                return body.module;
            })
            .catch(error => null);

        if (result) {
            return result;
        }

        await new Promise(resolve => setTimeout(resolve, 500));  // Delay for 500 ms
    }
}

export const fireLoadMitModuleDispatch = (cidla) => {
    return () => {
        return waitForMitfedResponse(cidla)
            .then(mitModule => {
                Resource.setMitModule(mitModule);
                return Promise.resolve();
            })
    }
}